import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import useBlogStyles from "../../components/blog/styles";
import PostItem from "../../components/blog/post-item";
import SEO from "../../components/seo";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export default function Blog({ data }) {
  const { t } = useTranslation();
  const classes = useBlogStyles();
  const { posts } = data.blog;

  return (
    <Layout>
      <SEO title="Blog" />
      <Container maxWidth="sm">
        <Typography variant="h2" gutterBottom className={classes.title}>
          {t("blog.title")}
        </Typography>

        {posts.map(post => (
          <PostItem post={post} key={post.id} />
        ))}
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogQuery($language: String!) {
    blog: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      posts: nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          author
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
        id
        slug
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
