import React from "react";
import PropTypes from "prop-types";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MuiLink from "@material-ui/core/Link";
import ForwardedLink from "../../shared/components/forwarded-link";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  titleLink: {
    color: theme.palette.secondary.main,
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const PostItem = ({ post }) => {
  const classes = useStyles();
  const image = getImage(post.frontmatter.hero_image);
  return (
    <article className={classes.root}>
      <Card>
        {image && (
          <GatsbyImage
            image={image}
            alt={post.frontmatter.hero_image_alt}
            style={{ height: "250px" }}
          />
        )}

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <MuiLink
              component={ForwardedLink}
              to={`/${post.slug.split(".")[0]}`}
              className={classes.titleLink}
            >
              {post.frontmatter.title}
            </MuiLink>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.subtitle}
            color="textSecondary"
          >
            {post.frontmatter.author}, {post.frontmatter.date}
          </Typography>
          <Typography variant="body1">{post.excerpt}</Typography>
        </CardContent>
      </Card>
    </article>
  );
};

PostItem.propTypes = {
  post: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
    excerpt: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};
export default PostItem;
